import React, {useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
const api='https://kurtaghar.store:5000/api/v1/'

const ExtraStuff = () => {
    const [data,setData]  = useState()
    const {name} = useParams()
    console.log(name)
    useEffect(()=>{
        const fetchData=async()=>{
            try {
                let external = await axios.get(`${api}/cms_api`)
                setData(external.data)
            } catch (error) {
                console.log(error.message)
            }
        }
        fetchData()
    },[])
    useEffect(()=>{
        window.scroll(0,0)
    },[name])
  return (
    <>
    <div style={{ height:"130vh",  width:"95%", margin:"auto"}} >
    <h1 style={{textAlign:"center",marginTop:"10%",textTransform:"capitalize"}}>{name.replace('_'," ")}</h1>
    <div style={{  padding:"7px",textAlign:"justify",borderRadius:"10px" }} dangerouslySetInnerHTML={{ __html: data && data.data[name] }}/>
    </div>
    
    </>
  )
}

export default ExtraStuff

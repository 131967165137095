import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import {GlobalStore} from './context/GlobalStore'
import { ProductGlobalStore } from './context/productContext';
// import Footer from './Pages/Layouts/Footer';
// import Header from './Pages/Layouts/Footer';
// import {AppContext} from './context/ProductContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
    {/* <Header/> */}
   
    <GlobalStore>
    <ProductGlobalStore> 
    
       <App />
    
       </ProductGlobalStore>
    </GlobalStore>
    
    </BrowserRouter>
    
    {/* <Footer/> */}
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useState, useEffect } from "react";
import Header from "./Layouts/Header";

import Footer from "./Layouts/Footer";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductList from "../jsxFolder/ProductList";
import ClipLoader from "react-spinners/ClipLoader";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useProductGlobal } from "../context/productContext";
// import { max, min } from "@splidejs/splide/src/js/utils";
const apiURL = 'https://kurtaghar.store:5000/api/v1';
const backendURL = 'https://kurtaghar.store:5000/';
function Productlist({wishList}) {

    const {priceHandle,filteredItems
        ,handleCheckboxChange,
        counter,
        setSelectedAll
        ,Id,setId,products,setProducts,categoryFilter,setCategoryFilter,loading,setLoading,selectedCategories,setPriceRange,selectedPriceRange,setSelectedCategories,backup,setBackup,hash,sethash,allCheck,setallCheck,wish,setWish} = useProductGlobal()
        // const [isMobile, setIsMobile] = useState(false);
        const [filterOpen, setFilterOpen] = useState(false);
        const { id } = useParams(); 
        
    const navigate=useNavigate()
    useEffect(() => {
        window.scrollTo(0,0)
        if(id){
            setId(id)
        }
        if(wishList){
            setWish(wishList)
        }

    }, [id]);

      const handleFilterToggle = () => {
        setFilterOpen(!filterOpen);
      };
useEffect(()=>{
    if(isMobile){
        setFilterOpen(false)
    }
    else{
        setFilterOpen(true)
    }

},[])
    return (
        <>   
        
            {/* <Header /> */}
<section className="Seb-header bg-white" style={{height:'20px !important'}}> 
        {/* <div className="seb-body"> */}
            {/* <div className="container"> */}
                {/* <h4 className="text-center text-light"></h4> */}
            {/* </div> */}
        {/* </div> */}
     </section>

            <section className='product-listing '>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                                {isMobile && (
                                    <div className="filter-toggle " onClick={handleFilterToggle}>
                                    <i className="fas fa-filter" />
                                    </div>
                                )}
                                 {filterOpen && (
                                <div className="fiter-itm">
                                {/* category filter content */}
                                
                                {/* <h3 className="filer-heading">CATEGORY</h3> */}
                                {/* ... category filter items ... */}
                                <div className='product-listing-filler d-flex'>
                                <div className=''>
                                    <h3><i className='fas fa-filter'></i> FILTER</h3>
                                </div>
                                <div className=''></div>
                                <h6 className="clearALL" onClick={()=>{
                                    setProducts([...backup])
                                    sethash({})
                                    setSelectedCategories([])
                                    setSelectedAll(true)
                                    setPriceRange([])

                                }
                                    }> <u>Clear All</u></h6>
                            </div>

                            <div className='fiter-itm'>
                                <h3 className='filer-heading'>CATEGORY</h3>
                                <div className='filter-itm-1'>
                                      <div className='filter-itm-2'>

                    <input className='form-check-input'   type="checkbox"
                        value={'all'}
                        id={'all'}
                        checked={allCheck}
                        // checked={selectedCategories.includes(value.id)}
                        onChange={handleCheckboxChange}
                          />
                                    <label className='form-check-label' htmlFor={'all'}>
                                        {'All'}
                                    </label>                  
                                      </div>
                                </div>
                                {categoryFilter.categories && categoryFilter.categories.map((value,inx)=>{
                                    
                                    return (
                                        <>
                    <div className='filter-itm-1'>
                    
                    <div className='filter-itm-2'> 
                <input className='form-check-input'   type="checkbox"
                        value={value.id}
                        checked={hash[value.id]}
                        id={value.id}
                        // checked={selectedCategories.includes(value.id)}
                        onChange={handleCheckboxChange}  />
                                    <label className='form-check-label' key={value} htmlFor={value.id}>
                                        {value.title} &nbsp; <span style={{color:"grey",fontSize:"13px"}}>{counter[value.id]}</span>
                                    </label>
                    </div>
                </div>  
                                        </>
                                    )
                                })}
                               
                            </div>
                            <hr style={{margin: '0px' ,color: '#AEAEAE'}} />
                            <div className='fiter-itm'>
                            <h3 className='filer-heading'>Price</h3>
                            <div className='filter-itm-1'>
                    
                                       
                                   
                                    
                                    <input
                                    className='form-check-input'
                                    onChange={priceHandle}
                                    type='checkbox' value={1000}/>
                                    &nbsp; &nbsp;
                                       <label className='form-check-label' key={''} htmlFor={''}>
                                        0 - 1000
                                    </label> 
                                   </div> 
                    
                                   <div className='filter-itm-1'>
                                  
                                             
                                   
                                    
                                    <input type='checkbox'
                                    className='form-check-input'
                                    onChange={priceHandle}
                                    value={2000}/>
                                    &nbsp; &nbsp;
                                    <label className='form-check-label' value={''} key={''} htmlFor={''}>
                                        1000 - 2000
                                    </label>
                                  
                                    </div>
                                    <div className='filter-itm-1'>
                                  
                                             
                                  
                                   
                                   <input type='checkbox'
                                   className='form-check-input'
                                   onChange={priceHandle} value={categoryFilter.maxPrice}/>
                                 &nbsp; &nbsp;
                                 <label className='form-check-label' key={''} htmlFor={''}>
                                       2000 - Above 
                                   </label>
                                   </div> 
                                    
                                  
                    {/* </div>dd */}
                            </div>
                                </div>
                            )}
                           
                        </div>

                        <div className='col-lg-9 ' >
                            <section className='trending-product-1 tp_space'>
                                <div className='row'>
                                         {loading?
                                         <div className={'d-flex'} style={{justifyContent:"center" , alignItems:"center"}}>
                                             <ClipLoader  color="#3498db" loading={loading} size={50} />
                                         </div>
                                        :
                                         <>
                                         {products.length>0?
                                         <>
                                            {products ?
                                             filteredItems.map((value,inx)=>{
                                                
                                        return (
                                            <div className="listingProduct col-lg-4 col-md-6 col-sm-5">
                                                <ProductList  data = {value} index = {inx}  />
                                            </div>  
                                            )          
                                        })
                                        
                                        :<>
                          
                                        </>}
                                         </>:
                                           
                                         <>
        <div className='h1 ' style={{ position:"relative"}}>No Products Found</div>
                                   
                                         </>}
                          

                                         </>}                               
                                       
                                
                                
                                
                                    
                                </div>
                            </section> 
                        </div>       

                    </div>
                </div>
            </section>

            {/* <Footer /> */}

        </>
    );
}

export default Productlist;

import React from 'react';
import { Routes, Route,  } from 'react-router-dom';
// import Index from './Pages/Index';
import Index from './Pages/Index';
import ProductList from './Pages/productlist';
import "react-toastify/dist/ReactToastify.css"
import ProductDetails from './Pages/ProductDetails';
import Header from './Pages/Layouts/Header';
import Footer from './Pages/Layouts/Footer';
import ExtraStuff from './components/ExtraStuff';

function App() {
 

  return (
    <div>

    <Header/>
    {/* <BrowserRouter> */}

      <Routes>
        <Route path="/" element={<Index />} />
        <Route path='/product' element={<ProductList/>}/>
        <Route path="/product/:id" element={<ProductList />} />
        <Route path='/ProductDetails/:id' element={<ProductDetails/>}/>
        <Route path='product/filter' element={<ProductList wishList={true}/>}/>
        <Route path='/extra/:name' element={<ExtraStuff/>}/>
      </Routes>

    {/* </BrowserRouter> */}
    <Footer/>
    </div>
  );
}

export default App;
// import React from "react";
// import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios, { Axios } from "axios";
import { useState } from "react";
import "./crr.css"
import { Link } from "react-router-dom";



import React, { useEffect, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import Image1 from './Images/img1.jpg'
// import Image2 from './Images/img2.jpg'
// import Image3 from './Images/img3.jpg'
// import Image4 from './Images/img4.jpg'
// import Image5 from './Images/img5.jpg'
let options = {
  items: 3,
  loop: true,
  margin: 10,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  nav: true,
  dots: true,
  responsive: {
      0: {
          items: 1
      },
      600: {
          items: 2
      },
      1000: {
          items: 3
      }
  }
}
let option2={
  loop: true,
  responsiveClass: true,
  nav: true,
  dots: true,
  margin: 0,    
  autoplayTimeout: 4000,
  smartSpeed: 400,
  center: true,
  navText: ["<i className='icon fas fa-chevron-left'></i>","<i className='icon fas fa-chevron-right'></i>"],
  responsive: {
      0: {
          items: 1,
      },
      600: {
          items: 2
      },
      1200: {
          items: 3
      }
  }
  ,
  autoplay:true,
    loop:true,
    margin:0,
    dots:true,
    nav:false,
    items:1
}
const Crausel = ({data}) => {
// console.log(data)
let arr=[...data.data]
  return (
<OwlCarousel className='owl-carousel owl-theme Topbanner'  id="banner-slide" {...option2}>
  {arr.map((value,inx)=>{
    return( 
    //    <div className='item'>
    //   {/* <h4>{value}</h4> */}
    //   <img src={value}/>
    // </div>
    <div className="banner-slide-innr " >
                <Link to='/product'>
                {/* style={{height:"280px" , width:"350px"}} */}
                <img src={data.backendURL+value.banner_image}   alt=""/>
								<div className="banner-slide-innr-content">
									<Link to="/product" className=''>{value.title}</Link>
								
                </div> 
                </Link>
                
								
		</div>
    )
})}
    
</OwlCarousel>  );
};

export default Crausel;

// const  Crausel =({data}) => {
    
//   return (    
//    <>
   
//    </>
//   //    <div className="crr">
//   //       <input type="radio" name="slider" id="item-1" defaultChecked="" />
//   //       <input type="radio" name="slider" id="item-2" />
//   //       <input type="radio" name="slider" id="item-3" />
//   // <div className="cardsBuilt">
//   //      {data.bannerImages.map((value,inx)=>{
//   //       console.log(value)
//   //       return  (
//   //           <>
//   //             <label className="cardBuilt" htmlFor={`item-${inx+1}`} id={`song-${inx+1}`}>
//   //               <img
//   //               src={data.backendURL + value.banner_image}
//   //               alt=""
                
//   //           />
//   //           <div className="upperLink" >
//   //           <Link className="Link-">{value.title}</Link>
//   //           </div>
            
//   //   </label>     
//   //           </>
//   //       )
//   //   })} 
//   // </div>
//   // </div>

//   );
// }
// export default Crausel;

// import 'react' from react
import axios from 'axios';
import React ,{ useState , useEffect , useContext ,createContext} from 'react' 
import { useGlobal } from './GlobalStore';
import { useNavigate } from 'react-router-dom';
const apiURL = 'https://kurtaghar.store:5000/api/v1';
const backendURL = 'https://kurtaghar.store:5000/';
const frontendURL = 'http://localhost:3000/';

let mainContext = createContext()
let ProductGlobalStore=({children})=>{
    // let {backendURL,frontendURL,apiURL} = useGlobal()
    const [categoryFilter,setCategoryFilter]  = useState([]) 
    const [products, setProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPriceRange,setPriceRange] = useState([])
    const [backup,setBackup] = useState([])
    const [loading,setLoading] = useState(true)
    const [hash, sethash] = useState({})
    const [wish,setWish] = useState(false)
    const [selectedSearch , setSearch] = useState('')
    const [allCheck,setallCheck]= useState(false)
    const [Id , setId] = useState()
    const [pink,setPink] = useState(-1)
    const [counter , setCounter] = useState({})
    const [confirmSearch,SetConfirmSearch] = useState('')
     const navigate=useNavigate()

    useEffect(()=>{

        let fetchCategoryData = async()=>{
            try {
                setLoading(true)
                let data = await axios.get(`${apiURL}/filter-list`)
                    // console.log(data.data)
                setCategoryFilter(data.data)
                setLoading(false)
                
            } catch (e) {
              console.log(e.message)
              setLoading(false)
            }
        }
        
        fetchCategoryData()
        
     },[])

     useEffect(()=>{
        const fetchFunction = async()=>{
            
            try{
                if(Id){
                    setLoading(true)
                    let FilteredData = await axios.get(`${apiURL}/product?categoryId=${Id}`)
                    setProducts(FilteredData.data)
                    console.log('data comming from one to one')
                    setLoading(false)
                }
                else{
                    setLoading(true)
                    let data=await axios.get(`${apiURL}/product`)
                    console.log('data comming purely')
                    setProducts(data.data)
                    setBackup(data.data)
                    setLoading(false)
                }
                                
            }
            catch(e){
            console.log(e.message)   
            setLoading(false)
            }
            


        }

        
        fetchFunction()
        if(Id){
            handleNavbarClick(Id)
        }
        
    },[Id])
 const  handleCheckboxChange  = (category) => {
    setPink(-1)
    setId(undefined)
    if(wish){
        setWish(false)  
        navigate('/product')
    }
    if(category.target.value=='all'){
        navigate('/product')
        setallCheck(true)
        sethash((pre)=>{
            let previous= pre
            for(let x of categoryFilter.categories){
                previous[x.id] = true
            }
            return previous
        })                      
        setSelectedCategories((pre)=>{

            let prev = [...pre]
            for(let y of categoryFilter.categories){
                prev.push(y.id.toString())
            }
            prev=[...new Set([...prev])]
            console.log(prev)
            return prev
            // return prev 

        })
        return 0
    }
    
setallCheck(false)
setSelectedCategories((prev) => {
    if (prev.includes(category.target.value)) {
        sethash((pre)=>{
            let preID= category.target.value
            pre[preID.toString()]=false
            return pre
        })      
      // Remove category if it is already selected
      return prev.filter(c => c !== category.target.value);

    } else {
      // Add category if it is not selected
      sethash((pre)=>{
          let preID= category.target.value
          console.log(preID)
          pre[preID.toString()]=true
            return pre
    })
      return [...prev, category.target.value];
    }

  });

      navigate('/product')

  };



  function handleNavbarClick(c) {
    setallCheck(false)
    setPink(c)
    setSelectedCategories((prev) => {
        // if(!prev.includes(c)){
        //     return [c]
        // }
        // return []
        return [c]
    });
            
    sethash((prev)=>{
        let newPrev = {}
        newPrev[c.toString()] = true
        return newPrev
    })
    if(categoryFilter.categories){
        if(selectedCategories.length===categoryFilter.categories.length){
            navigate('/product')
        }
      };
    }
    useEffect(()=>{
       console.log(selectedCategories)
       console.log(hash)
        let categoriesData = categoryFilter.categories
    let len;
    if(categoriesData){
        len=categoriesData.length
        if(selectedCategories.length===0 || selectedCategories.length==len){
            setallCheck(true)
        }
        
        if(selectedCategories.length>0 && selectedCategories.length<len){
    
            setallCheck(false)
        }    
    } 

    },[selectedCategories,selectedPriceRange,hash,categoryFilter,selectedSearch])
    const priceHandle=(event)=>{
        let data = event.target.value 
        setPriceRange((prev)=>{
             prev=[...prev]
            if(prev.includes(data)){
                return prev.filter(c => c !== data)
            }
            else{
                return [...prev,data]
            }
        })
      }
      let filteredItems=[]

  if(!wish){
    console.log(products) 
     filteredItems = confirmSearch ? confirmSearch : products   
     filteredItems = filteredItems.filter(item =>{  
        let Fillcategory =  selectedCategories.length === 0 || selectedCategories.includes('all') || selectedCategories.includes(item.category)
        return Fillcategory
      }
      );
      if(selectedPriceRange.length>0){
                    let allData = []
                for(let i of selectedPriceRange){
                    let main=filteredItems.filter((value)=>{
                        console.log(value.price < i )
                        return Number(value.price) <= Number(i)
                    })
                    allData.push(main)
                }
                allData= [...new    Set(allData.flat(Infinity))]
                filteredItems=allData
                
      }
  }
  else{
    let localStorageData = JSON.parse(localStorage.getItem('wish'))
    filteredItems = products.filter((value)=>localStorageData.includes(value.category))
        
      
  }
  let searchHandle=(e)=>{
    e.preventDefault()
    let searchData=selectedSearch
    let categoryFirst=[];
    let arr=[]
    categoryFirst = categoryFilter.categories.map((value )=>{
         
          if( value.title.toLowerCase().includes(searchData)){
            return value.id.toString()
          }
          
    })

    let filterDataMain=products.filter((value,inx)=>{
        console.log(value.name.includes(searchData))
        if(value.name.includes(searchData) || categoryFirst.includes(value.category)){
            return value
        }

              
    })

    SetConfirmSearch([...filterDataMain])
    navigate('/product')
  }
  useEffect(()=>{
     let innerCounter={}
     for(let x of products){

        if(innerCounter[x.category] > 0){
            innerCounter[x.category]++
        }
        else{
            innerCounter[x.category]=1
        }
     }  
     
     setCounter(innerCounter)
    },[products])
    return(
        <mainContext.Provider value={{
            pink,setPink,
            searchHandle,priceHandle,filteredItems,setSearch,selectedSearch,
            Id,setId,products,counter,handleCheckboxChange,setProducts,categoryFilter,setCategoryFilter,filteredItems,loading,setLoading,selectedCategories,setPriceRange,selectedPriceRange,setSelectedCategories,backup,setBackup,hash,sethash,allCheck,setallCheck,wish,setWish}}>
             {children}
    </mainContext.Provider>
    )
}

//  custom hook
let useProductGlobal = ()=>{
    return useContext(mainContext)
}

export {ProductGlobalStore,useProductGlobal}
 

// import 'react' from react
import axios from 'axios';
import React ,{ useState , useEffect , useContext ,createContext} from 'react' 
const apiURL = 'https://kurtaghar.store:5000/api/v1';
const backendURL = 'https://kurtaghar.store:5000/';
const frontendURL = 'http://localhost:3000/';

let mainContext = createContext()
let GlobalStore=({children})=>{
    const [home , setHome] = useState([])
    const [loading,setLoading] = useState(false)  
    useEffect(()=>{
        const fetchFunction = async()=>{
            try{
                setLoading(true)
                let data=await axios.get(`${apiURL}/home_api`)
                setHome(data.data)
                setLoading(false)                
            }
            catch(e){
            console.log(e.message)   
            setLoading(false)
            }
            


        }


        fetchFunction()
    },[])

    return(
        <mainContext.Provider value={{home,setHome,loading,setLoading,backendURL,frontendURL,apiURL}}>
             {children}
    </mainContext.Provider>
    )
}

//  custom hook
let useGlobal = ()=>{
    return useContext(mainContext)
}

export {GlobalStore,useGlobal}

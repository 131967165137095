import React , { useState,useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const apiURL = 'https://kurtaghar.store:5000/api/v1';

function Footer() {
        const [ModuleSettings, setModuleSettings] = useState([]);
        const [des,setDes]= useState([])
    useEffect(() => {
        // Fetch category images from the API
        const fetchModulesetting = async () => {
            try {
                // const response = await axios.get(`${apiURL}/category`);
                const response = await axios.get(`${apiURL}/module_settings`, {
                    params: {
                        limit: 2 // Specify the limit to fetch only 10 categories
                    }
                });
               
                setModuleSettings(response.data);
            } catch (error) {
                console.error("Error fetching category images:", error);
            }
        };
        let fetchTestiMonial=async()=>{
            try {
                let testiData = await axios.get(`${apiURL}/home_api`)
                setDes(testiData.data.webDetail)
            } catch (error) {
                console.log(error.message)
            }
        }

        fetchModulesetting();
        fetchTestiMonial()
    }, []);




    return (
        <>
          		<footer className="thm-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <h5 className="ftr-head">About us</h5>
                                <div className="ftr-abt">
                                    <p className="handleDangreous"   style={{height:"17vh"  ,overflow:"hidden"}}   dangerouslySetInnerHTML={{ __html:des.about_us }}/>
                                    <Link target="_blank" to={'https://api.whatsapp.com/send?phone=' + ModuleSettings.whatsapp}><i className="fab fa-whatsapp"></i></Link>
                                    <Link target="_blank" to={ModuleSettings.instagram}><i className="fab fa-instagram"></i></Link>
                                    <Link target="_blank" to={ModuleSettings.facebook}><i className="fab fa-facebook-f"></i></Link>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <h5 className="ftr-head">LEGAL</h5>
                                <ul className="ftr-lnk">
                                    <li><Link to="/extra/return_refund" >Returns & Refund Policy</Link></li>
                                    <li><Link to="/extra/terms_condition">Terms & Conditions</Link></li>
                                    <li><Link to="/extra/privacy_policy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <h5 className="ftr-head">HELP</h5>
                                <ul className="ftr-lnk">
                                    <li><Link to={`/extra/about_us`}>About Us</Link></li>
                                    <li><Link to={`/extra/contact_us`}>Contact Us</Link></li>
                                    <li><Link to={`/extra/`}>Track Your Order</Link></li>
                                    <li><Link to={`/extra/`}>FAQ's</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <h5 className="ftr-head">Newsletter</h5>
                                <div className="ftr-cta">
                                    <form action="">
                                        <div className="mb-3">
                                            <input type="text" className="form-control" placeholder="Enter Email Address"/>
                                        </div>
                                        <div className="mb-3">
                                            <button className="thm-btn w-100" type="button">Submit</button>
                                        </div>
                                        <p>Sign up to get the latest on new Products, Promotions, and more</p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <p className="text-center mb-0">Copyright © 2024 Kurta ghar All Rights Are Reserved.</p>
                        </div>
                    </div>
                    <div className="bttm-fx">
                        <Link target="_blank" to={'https://api.whatsapp.com/send?phone=' + ModuleSettings.whatsapp}><i className="fab fa-whatsapp"></i></Link>
                    </div>
                </footer>
        </>
    )
}

export default Footer;
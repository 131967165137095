import React, { useState,useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import { useProductGlobal } from "../../context/productContext";
const apiURL = 'https://kurtaghar.store:5000/api/v1';
const backendURL = 'https://kurtaghar.store:5000/';  
const frontendURL = 'http://localhost:3000/';



function Header() {
    const {categoryFilter,loading,selectedSearch,setSearch,searchHandle,pink,setPink} = useProductGlobal()
    const [categories,setCategories] = useState([])
    const [ModuleSettings, setModuleSettings] = useState([]);
    console.log(categoryFilter)
    useEffect(() => {
        // Fetch category images from the API
        const fetchModulesetting = async () => {
            try {
                // const response = await axios.get(`${apiURL}/category`);
                const response = await axios.get(`${apiURL}/module_settings`, {
                    params: {
                        limit: 2 // Specify the limit to fetch only 10 categories
                    }
                });
               
                setModuleSettings(response.data);
            } catch (error) {
                console.error("Error fetching category images:", error);
            }
        };

        fetchModulesetting();
    }, []);


    
    return (
        <>
            <header className="main-header fixed-top">
                <div className="tpbar">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5 col-sm-4 col-12">
                                <div className="tpbar-lft">
                                    <h5>Shop For Rs.5999 & Get Rs.300 OFF*. Use Code:KG300 </h5>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-8 col-12 text-end">
                                <ul className="tpbar-lnk">
                                   
                                    <li><Link target="_blank" to={'mailto:'+ ModuleSettings.email}> <i className="fal fa-question-circle me-2"></i>Help And Support</Link></li>
                                    <li><Link target="_blank" to={'https://api.whatsapp.com/send?phone=' + ModuleSettings.whatsapp}> <i className="fab fa-whatsapp icon"></i></Link></li>
                                    <li><Link target="_blank" to={ModuleSettings.instagram}> <i className="fab fa-instagram icon"></i></Link></li>
                                    <li><Link target="_blank" to={ModuleSettings.facebook}> <i className="fab fa-facebook-f icon"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg custom-navbar">
                    <div className="container">
                        <Link className="navbar-brand" to={"/"}>Kurta Ghar</Link>
                        <Link className="search-btn search_btn d-lg-none d-lg-inline-block ms-auto me-3"><i className="far fa-search"></i></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                {categoryFilter.categories &&   categoryFilter.categories.map((cat, index) => {
                                   return( <li className="nav-item" key={index}>
                                        <Link to={`/product/${cat.id}`} style={{color:pink==index?"pink":"black"}}  className="nav-link"
                                        onClick={()=>setPink(index)}
                                        >{cat.title}</Link>
                                    </li>)
                                })}
                            </ul>
                            <div className="navbar-item">
                                <Link className="search-btn search_btn d-none d-lg-inline-block"><i className="far fa-search"></i></Link>
                                <Link to="/product/filter"> <i className="fal fa-heart"></i></Link>
                                
                                
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="tp_search_bar ">
                    <form action=""  onSubmit={searchHandle} >
                        <div className="tp_search_bar_bx me-2">
                            <input type="text" className="form-control" placeholder="Search Product.."  onChange={(e)=>{
                                setSearch(e.target.value)
                            }}/>
                            <button type="submit" ><i className="far fa-search"
                            ></i></button>
                        </div>
                        <div>
                            <Link className="remove_bar"><i className="fas fa-times"></i></Link>
                        </div>
                    </form>
                </div>
            </header>
            <ToastContainer />
        </>
    )
}

export default Header;
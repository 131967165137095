import axios, { Axios } from "axios";
import { useState } from "react";
// import "./crr.css"
import { Link } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import image1 from '../images/Group 152190.png'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css'; 
import Crausel from "../components/Crausel";
import { PiCurrencyInrBold } from "react-icons/pi";
const apiURL = 'https://kurtaghar.store:5000/api/v1';
const backendURL = 'https://kurtaghar.store:5000/'; 
const ProductList = ({data , index}) => {
    
    let options={
        autoplay:true,
        loop:true,
         margin:0,
        dots:true,        
        nav:false,
        items:1 ,
        }
  return (
    <>
    <div className='trending-innr ' >
    <OwlCarousel className='owl-carousel owl-theme trending-product-slide own-product' {...options} >
         {data.productImages.length>0?<>
            {data.productImages.map((value,inx)=>{
            
            return(
            <div className="item product-slide-img  "  >
                <img src={backendURL+value.image_path} style={{width:"100%" , height:"100%"}}   alt=""/>
            </div>
            )
        })}
         </>:<>
         <div className="item product-slide-img " >
                <img src={image1}  alt=""  style={{width:"100%" , height:"100%"}}/>
            </div>
            
         </>}
        
        	
    </OwlCarousel>
    

    <div className="product-slide-content  " >
            <Link to={`/ProductDetails/${data.id}`} className="brdr-btn">View Details</Link>
        </div>        
    </div>

        <p>{data.name.slice(0,10)}... </p>
        <h5><PiCurrencyInrBold/> {data.price}  <del><PiCurrencyInrBold/> {data.compare_price}</del> </h5>
    

        {/* <div className='col-lg-9 col-md-6 col-sm-6' > */}
                    {/* <div className='trending-innr'  >
                    <Splide
                        options={{
                            type: 'loop',
                            
                            perPage: 1,
                            pagination: true,
                            autoplay: true,
                            interval: 3000, // Change slide every 3 seconds
                            pauseOnHover: true,
                            arrows: false,
                            breakpoints: {
                                600: {
                                    perPage: 1,
                                },
                                900: {
                                    perPage: 2,
                                },
                            },
                        }}
                    >
                        {data.productImages.map((image) => (
                        <SplideSlide key={image.id} className={"ProductSLide"}  >
                            <img src={backendURL+image.image_path}
                            style={{height:"360px",width:"300px",borderRadius:"15px"}}
                            alt={`Slide ${image.title}`} />
                        </SplideSlide>
                    ))}
                    </Splide>
                        
                            <p>{data.name}</p>
                            <h5 >{data.price} &nbsp; <del>{data.compare_price}</del></h5>
                        
                        
                        
                        <div className="product-slide-content" style={{position:"relative" }}>
							<Link to={`/ProductDetails/${data.id}`} className="brdr-btn " >View Details</Link>
						</div>
                    </div> */}
                {/* </div> */}
                </>
  )
}

export default ProductList

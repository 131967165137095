import React, { useState,useEffect } from "react";
import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";
import axios from "axios";
import image1 from '../images/Group 152190.png'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import "../index.css"     
import notAvailable from '../images/notavailable.webp'
import Crausel from "../components/Crausel";
import { Link } from "react-router-dom";
// import "./caurausel.css"
import ProductList from "../jsxFolder/ProductList";
import { Autoplay } from "swiper/modules";
import { ClipLoader } from "react-spinners";
import { useGlobal } from "../context/GlobalStore";
function Index() {
   let {home ,  loading , backendURL} = useGlobal()
   useEffect(()=>{
    window.scrollTo(0,0)
},[home])

    return (
        <>
          {loading?
          <div style={{height:"80vh" , display:"flex",justifyContent:"center",alignItems:"center"}}>
             <ClipLoader color="Pink" loading={loading} size={60} />
          </div>
          :<>
            <section className="banner-sec">
                <div className="container">
                    <div className="row align-items-center"> 
                        <div className="col-lg-5 col-md-4 col-sm-4 my-2">
                            <div className="banner-content">
                                <h1>Exclusive Offer <br /> Order Now </h1>
                          
                                <Link to="/product" className="thm-btn">Buy Now</Link>
                            </div>
                        </div>
                        
                        { <div className="col-lg-7 col-md-8 col-sm-8">
                           {home.banner && <Crausel data = {{data:home.banner,backendURL}}/> }
                            
                        </div> }
        

                    </div>
                </div>
            </section>

            <section className="trending-product tp_space">
                <div className="container">
                    <div className="headings">
                        <h2>Curated Exclusively for you</h2>
                        <h3><span className="text-uppercase">trending products</span></h3>
                        <p>Women's Ethnicwear & Westernwear for everyday & ocassions</p>
                    </div>
                    <div className="row d-flex">
                        {home.tranding_products &&  
                        
                        home.tranding_products.map((value,inx)=>{
                            if(inx<4){
                                return (
                                    <div className='forTrending  col-lg-3 col-md-6 col-sm-6 mb-4' >
                                  <ProductList data={value} index={inx} />
                                  </div>
                                  )     
                            }
                            
                         })
                        }

                       
                        <div className="text-center ">
                            <Link to="/product" className="prdct-btn">View All</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category-sec tp_space">
                <div className="container">
                    <div className="headings">
                        <h2>Curated Exclusively for you</h2>
                        <h3><span>Shop By Category</span></h3>
                        <p>Women's Ethnicwear & Westernwear for everyday & ocassions</p>
                    </div>
                    <div className="row  justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                            <div className="row">
                                {home.categorie && 
                                home.categorie.map((cat, index) => {
                                    if(index<6){
                                        return(
                                            <div className="col-lg-4 col-md-4 col-6 mt-2" key={index}>
                                                <div className="category-innr">
                                                {/* style={{height:"300px",width:"350px"}} */}
                                            
                                                <Link to={`/product/${cat.id}`}>
                                                <img src={ cat.image ? backendURL + cat.image :  image1 } style={{height:"50vh"}}    alt="" />
                                                    <div className="category-innr-cont" >
                                                        <Link href="" style={{background:"rgba(255, 192, 203, 0.653)"}}>{cat.title}</Link>
                                                    </div>
                                                </Link>
                                                    
                                                </div>
                                            </div>)    
                                    }
                                    
                                })}
                                

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="arrivals  tp_space ">
            {/* style={{width:"93%"}} */}
                <div className="container"  >
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-4 col-sm-4">
                            <div className="arrivals-txt text-center">
                                <h3>New Arrivals</h3>
                                <Link to='/product' className="prdct-btn">View All</Link>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-8 " >
                           
                                <Splide 
                                options={{
                                
                                autoplay: true,
                                perPage: 3 ,
                                interval: 3000,
                                pagination: true,
                                arrows: true,
                                breakpoints : {
                                    1024: {
                                        perPage: 3,
                                        gap: '1rem',  
                                    },
                                    768: {
                                      // Settings for screens with a width of 768px or larger
                                      perPage: 2,
                                      gap: '1rem',
                                    },
                                    480: {
                                      // Settings for screens with a width of 480px or larger
                                      perPage: 2,
                                      gap: '0.5rem',
                                    },
                                  }
                                
                                }} >
                                    {/* // Add other options as needed */}
                                    
                                     {home.newarrival_products  &&
                                     
                                     home.newarrival_products.map((value,inx)=>{
                                        return (
                                          <> 
                                               <SplideSlide  >
                                                   <div className="item">
                                                   <ProductList data={value} index = {inx} />
                                                    </div>   
                                              </SplideSlide>                                      
                                              
                                          </>
                                        )
                                     })
                                     

                                     }
                                      
                                   </Splide>
                                  
                                
                           
                        </div>
                    </div>
                </div>
            </section>
            <section className="arrivals lenga-sec tp_space">
            {/* style={{width:"96%"}} */}
                <div className="container" >
                    <div className="row align-items-center">
                    <div className="col-lg-9 col-md-8 col-sm-8">
                  
                                
                                <Splide 
                                options={{
                                // width: '100%',
                                autoplay: true,
                                perPage: 3,
                                interval: 3000,
                                pagination: true,
                                arrows: true,
                                breakpoints : {
                                    1024: {
                                        perPage: 3,
                                        gap: '1rem',  
                                    },
                                    768: {
                                      // Settings for screens with a width of 768px or larger
                                      perPage: 1,
                                      gap: '1rem',
                                    },
                                    480: {
                                      // Settings for screens with a width of 480px or larger
                                      perPage: 2,
                                      gap: '0.5rem',
                                    },
                                  }
                                // breakpoints : {
                                //     768: {
                                //       // Settings for screens with a width of 768px or larger
                                //       perPage: 1,
                                //       gap: '1rem',
                                //     },
                                //     480: {
                                //       // Settings for screens with a width of 480px or larger
                                //       perPage: 2,
                                //       gap: '0.5rem',
                                //     },
                                //   }
                                }} >
                         {home.products_by_category && 
                         
                         home.products_by_category.map((value,inx)=>{
                            return value.products.map((val, inx)=>{
                                 return(
                                   <>
                                   <SplideSlide >
                                        <div className='item'>
                                     <ProductList data={val} index={inx}/>
                                         </div>
                                        
                   
                                     </SplideSlide>
                                   </>  
                                 ) 
                            })
                            
                             
                         })}
                         


                          
                        
                           
                            
                            </Splide>
                                  
                                
                  
                        
                            

                    </div>
                    
                            
                        
                        <div className="col-lg-3 col-md-4 col-sm-4">
                            <div className="arrivals-txt text-center">
                                <h3>Lenga sets</h3>
                                <Link to="/product" className="prdct-btn">View All</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="colection tp_space ">
                <div className="container bannerImagesData">
                <Splide options={
                     {
                        type:"loop",
                        Autoplay:true,
                        // fixedHeight: 400,
                        heightRatio: 0.4,
                        interval:3000,
                        pauseOnHover: true,
                        // breakpoints : {
                        //             1024: {
                        //                 perPage: 3,
                        //                 gap: '1rem',  
                        //             },
                        //             768: {
                        //               // Settings for screens with a width of 768px or larger
                        //               perPage: 1,
                        //               gap: '1rem',
                        //             },
                        //             480: {
                        //               // Settings for screens with a width of 480px or larger
                        //               perPage: 2,
                        //               gap: '0.5rem',
                        //             },
                        //           }
                     }
                } >

                {home.offerbanner && home.offerbanner.map((offerbannerItem, index) => (
                                  
                                    <SplideSlide >

                                    <img src={offerbannerItem.image ?backendURL+offerbannerItem.image:image1} alt="Image 1"  style={{height:"100%", width:"100%"}}  />
                                    <Link href="#"  className="thm-btn" style={{position:"relative",left:
                                        "17%",bottom:"23%"}}  >shop now</Link>
                                    
                                </SplideSlide>
                            
                        
                        ))} 
    </Splide>
                </div>
            </section>
            <section className="about-sec tp_space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <div className="about-box">
                                <img src="assets/images/about-icon-01.png" alt=""/>
                                <h5>Natural Prints</h5>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <div className="about-box">
                                <img src="assets/images/about-icon-02.png" alt=""/>
                                <h5>For Women By Women</h5>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <div className="about-box">
                                <img src="assets/images/about-icon-03.png" alt=""/>
                                <h5>Hand Crafted</h5>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <div className="about-box">
                                <img src="assets/images/about-icon-04.png" alt=""/>
                                <h5>Made to order</h5>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <div className="about-box">
                                <img src="assets/images/about-icon-05.png" alt=""/>
                                <h5>Brand of Indian Values</h5>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                            <div className="about-box">
                                <img src="assets/images/about-icon-06.png" alt=""/>
                                <h5>80,000+ Happy customer</h5>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="about-innr">
                                <h4>About Kurta ghar</h4>
                                
                                
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: home.webDetail && home.webDetail.about_us}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial tp_space ">
                <div className="headings">
                    <h3><span>what say my customer</span></h3>
                </div>
                
            
                
                {/* <div  className="d-flex justify-content-around  ">         
                {home.testimonial &&  
            
               home.testimonial.map((value,inx)=>{
                return (
                    <>
                   <div style={{display:'flex',flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                   <img
                      style={{width:"100%",height:"200px", borderRadius:"10px"}}
                        src={value.image ?  backendURL+value.image : image1}
                        alt="profile-sample6"
                        />
                              <h5 className="text-center" >{value.name}</h5>
                              <h5 className="text-center" dangerouslySetInnerHTML={{__html:value.description?value.description:"<p> No description Here </p>"}}/>
                    </div> 
                    
                    </>
             

      


            
            
                ) 
             }) }
             </div>  */}
            <div className="testimonial-container">
  {home.testimonial && 
    home.testimonial.map((value, inx) => {
      return (
        <div key={inx} className="testimonial-item">
          <img
            src={value.image ? backendURL + value.image : image1}
            alt="profile-sample6"
            className="testimonial-image"
          />
          <h5 className="testimonial-name">{value.name}</h5>
          <div
            className="testimonial-description"
            dangerouslySetInnerHTML={{
              __html: value.description ? value.description : "<p> No description Here </p>",
            }}
          />
        </div>
      );
    })
  }
</div>
               
               
                
             

            </section>
            <section className="postes-sec ">
                <div className="container-fluid">
                    <div className="headings">
                        
                        <h3><span>Follow us on Instagram for the latest in publishing and creative design inspiration!</span></h3>
                        {/* <p></p> */}
                    </div>
                    <Splide
            options={{
                width:"100%",
                start: 0,   // Start at the 4th slide (0-indexed)
                perPage: 4, // Show 3 slides per page
                type: 'loop', // Enable looping
                pagination: true, // Show pagination dots
                arrows: false, // Disable navigation arrows
                breakpoints: {
                    600: {
                        perPage: 2,
                    },
                    900: {
                        perPage: 1,
                    },
                    1024:{
                        perPage:1
                    }
                },
                // breakpoints : {
                //     768: {
                //       // Settings for screens with a width of 768px or larger
                //       perPage: 3,
                //       gap: '1rem',
                //     },
                //     480: {
                //       // Settings for screens with a width of 480px or larger
                //       perPage: 2,
                //       gap: '0.5rem',
                //     },
                //   }
            }}
        >
             {home.insta_video && 
                home.insta_video.map((value,inx)=>{
                    console.log(value)
                      return(
                    <SplideSlide  key={inx} >
                        <Link to={value.video_link} target="_blank">
                        <img src={ value.thumbnail_image ?backendURL+value.thumbnail_image:image1} className='instaImg'   alt={`Slide ${inx}` } />
                        </Link>
                    </SplideSlide>)
                  })}
             
              
           </Splide>
                </div>
            </section>
            <hr style={{border:"none"}}/>
            <section className="delivery tp_space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="delivery-card">
                                        <div className="delivery-card-icon">
                                            <img src="assets/images/delivery-icon-01.png" alt=""/>
                                           </div>
                                        <div className="delivery-card-cntnt">
                                            <h6>delivery 100%</h6>
                                            <p>Order Now</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="delivery-card">
                                        <div className="delivery-card-icon">
                                            <img src="assets/images/delivery-icon-02.png" alt=""/>
                                        </div>
                                        <div className="delivery-card-cntnt">
                                            <h6>Secure Payment</h6>
                                            <p>100% risk-free shopping</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="delivery-card">
                                        <div className="delivery-card-icon">
                                            <img src="assets/images/delivery-icon-03.png" alt=""/>
                                        </div>
                                        <div className="delivery-card-cntnt">
                                            <h6>Customer Service</h6>
                                            <p>Give us feedback</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="delivery-card">
                                        <div className="delivery-card-icon">
                                            <img src="assets/images/delivery-icon-04.png" alt=""/>
                                        </div>
                                        <div className="delivery-card-cntnt">
                                            <h6>Special Campaigns</h6>
                                            <p>Guaranteed Saving</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
 
          </>}
          
        </>
    );
}

export default Index;

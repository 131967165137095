import React from 'react'
import {useState , useEffect} from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { PiCurrencyInrBold } from 'react-icons/pi'
import image1 from '../images/Group 152190.png'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { FaRegHeart } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import ProductList from '../jsxFolder/ProductList'
const apiURL = 'https://kurtaghar.store:5000/api/v1';
const backendURL = 'https://kurtaghar.store:5000/';

console.log(apiURL)
const ProductDetails = () => {
  const [quantity,setQuantity] = useState()
  const [data , setData] = useState([])
  const [description,setDescription] = useState(false)
  // const [categoryId,setCategoryId] = useState()
  const [similarProduct,setSimilarProduct] = useState([])
  let {id} = useParams()
  useEffect(()=>{
    let fetchData=async()=>{
      try {
        let fetchData = await axios.get(`${apiURL}/product-detail?productId=${id}`)
        setData(fetchData.data)
      } catch (error) {
         console.log(error.message)
      }
      
    }
    fetchData()
  },[id])
  useEffect(()=>{
    let fetchFunc=async()=>{
      let categoryId=data.category          
      try{
        let products  = await axios.get(`${apiURL}/product?categoryId=${categoryId}`) 
        setSimilarProduct(products.data)
          }catch(err){
          }
    }
    fetchFunc()
    
  },[data])
  const [ModuleSettings, setModuleSettings] = useState([]);
  useEffect(() => {
      // Fetch category images from the API
      const fetchModulesetting = async () => {
          try {
              // const response = await axios.get(`${apiURL}/category`);
              const response = await axios.get(`${apiURL}/module_settings`, {
                  params: {
                      limit: 2 // Specify the limit to fetch only 10 categories
                  }
              });
             
              setModuleSettings(response.data);
          } catch (error) {
              console.error("Error fetching category images:", error);
          } 

      };

      fetchModulesetting();
  }, []);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[id])
  let storage=()=>{

      let fetch = JSON.parse(localStorage.getItem('wish'))
      console.log(fetch)
     if(fetch===null){
        localStorage.setItem('wish',JSON.stringify([id]))    
     }
     else{
        if(!fetch.includes(id)){
          fetch.push(id)
          localStorage.setItem('wish',JSON.stringify(fetch))
        }
        
     }
  }
  return (
    
    <>

  {/* Product_detail */}
  <div className="Product_detail_Section tp_space">
    <div className="container m_t ">
      <div className="row">
        <div className="col-lg-6">
          <div id="demo" className="carousel slide" data-bs-ride="carousel">
            {/* Indicators/dots */}
          
            {/* The slideshow/carousel */}
        <Splide options={{
          width: '88%',
          fixedHeight:"400",
          autoplay: true,
          interval: 3000,
          pagination: true,
          arrows: false,
    // Add other options as needed
  }}>   {data.productImages && data.productImages.length>0?<>
  {data.productImages && data.productImages.map((value,inx)=>{
          
          return (
          <SplideSlide>
                <img
               src={backendURL+value.image_path}
               alt="Chicago"
               className="d-block"
               style={{ width: "100%" , height:"500px" , objectFit:"contain"  }}
             />
         </SplideSlide>)    
     })}
       </>:
       <>
                <img
               src={image1}
               alt="Chicago"
               className="d-block"
               style={{ width: "100%" , height:"500px"  }}/>
  </>}
        
            
          </Splide>
          
          </div>
          <div className="product-discription m_t2" >
            {/* <div className="tab" >
              
              <button className="tablinks"
              
              style={{backgroundColor:description==='additional'?'pink':'',textTransform:"capitalize", fontFamily:"sans-serif"}} onClick={()=>{setDescription("additional")}}>
                Addition Information
                
              </button>
              
              {description=='additional' ? <>
                   
              
              </>: <></>}
               
            </div> */}
                    
          </div>
        </div>
        <div className="col-lg-6">
          <div className="product-Item-details">
            <h4>{data.name}</h4>
            <Link to={'#'}  onClick={storage}><FaRegHeart/></Link>
            <h5>
            <PiCurrencyInrBold /> {data.price} <del><PiCurrencyInrBold />  {data.compare_price}</del>
            </h5>
            <p>
              Tax included.&nbsp;<u>Shipping</u>&nbsp;calculated at checkout.
            </p>
          </div>
          <hr style={{ color: "#D9D9D9" }} />
          {/* <div className="product-Item-details-1">
            <p>Size</p>
            <button> S</button>
            <button> M</button>
            <button> L</button>
            <button> XL </button>
          </div> */}
          <div className="product-Item-details-2 m_t1 handleDangreous2">
            <p>Description</p>
            <div 
            style={{height:description?`auto`:'200px',overflow:"hidden",fontFamily:"sans-serif !important"}}
            dangerouslySetInnerHTML={{ __html: data.description  }}

            >
            

            </div>
            {data.description?<>
            
              <button className='bg-success  mt-3 text-light '
            style={{borderRadius:"10px" , padding:"7px 14px"}}
            onClick={()=>{
              setDescription(!description)
            }}
            >Read More </button>
            
            </>:<>
            </>}
            
            
            {/* <img src="" alt=" " />
            <img src="" alt=" " />
            <img src="" alt=" " /> */}
          </div>
          <div className="Add_Btn m_t1">
            
            {/* <hr style={{ color: "#D9D9D9" }} /> */}
          </div>
          <div className="d-flex justify-content-between">
           <div style={{ width:"100%"}}>
           <div className="add_cart_btn "  >
              <div className="arrivals-txt text-center ">
                <Link to= {`https://api.whatsapp.com/send?phone=${ModuleSettings.whatsapp}`} style={{padding:"5px !important" , paddingLeft:"10px" ,paddingRight:"10px" , width:"40%", backgroundColor:"green" , color:"white " , border:"none"}} className="prdct-btn">
                          
                  Order on whatsApp <FaWhatsapp style={{fontSize:"22px"}}/>
                   {/* <samp>
                    <img src="" alt="" />
                  </samp>   */}
                </Link>
              </div>
            </div>
           </div>
            
          </div>
        
          {/* <hr style={{ color: "#D9D9D9" }} /> */}
        
        
        </div>
      </div>
    </div>
    <div className='col-lg-12 mt-3' >
    <div className="tab d-flex"  >
              
              <button className=""
              
              style={{backgroundColor:description==='additional'?'pink':'',textTransform:"capitalize", fontFamily:"sans-serif" , display:"block !important", margin:"auto",fontSize:"25px",color:"black"}} onClick={()=>{setDescription("additional")}}>
                Addition Information
              </button>
            
               
            </div>
    {/* <hr style={{color:"grey"}}/> */}

    <div className='container mt-3 addition'>
                  Order <b> Confirmation </b>
                
                <div style={{   textAlign:'justify'}}>
                Please note that your order will be confirmed only after payment is successfully received
                </div>
                <h5>payment Models </h5>
                <ul  className='providingStyle'>
                 <li >Bank Transfer</li>
                  <li>Google Pay</li>
                  <li>Paytm</li>
                </ul>
                <div style={{width:"90%",textAlign:"justify" , marginTop:"10px"}}>
                Once the payment is completed, please share the transaction details with us to proceed with your order.
                For any assistance, feel free to reach out to us!
                Parcel Opening Video : To ensure the integrity of your purchase and to provide smooth resolution in the event of any issues, we require that all customers record a video while opening the parcel. This unboxing video serves as evidence of the package’s condition upon delivery and is necessary for processing any claims related to damaged, missing, or incorrect items.
                </div>
                <h5>Please note :</h5>
                <ul className='providingStyle'>
                  <li>
                  Complaints regarding product quality, missing items, or any discrepancies will only be entertained if accompanied by a clear video showing the sealed parcel being opened.
                  </li>
                  <li>
                  Without this video, we regret that complaints or returns cannot be accepted.
                  </li>
                  <li>
                  This policy helps us maintain transparency and ensures both customer satisfaction and fair resolution.
                  </li>
                
                </ul>
                
                </div>
              
        
    </div>
        
  </div>
  {/* SIMILAR PRODUCTS section start */}
  <section className="simlar_product  tp_space">
    <div className="container">
      <div className="headings">
        <h2>Curated Exclusively for you</h2>
        <h3>
          <span className="text-uppercase">SIMILAR PRODUCTS</span>
        </h3>
        <p>Women's Ethnicwear &amp; Westernwear for everyday &amp; ocassions</p>
      </div>
      <div className="row" >
         {similarProduct.map((value,index)=>{
            return (
            <>
              <div className="listingProduct col-lg-3 col-md-6 col-sm-6 similar">
              <ProductList data={value} index={index}/>
              </div>
            
            </>
            
          )
         })}           
        {/* <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="trending-innr"> */}
               {/* {data.productImages.map((inx,value)=>{
                <img src={backendUrl+value.image_path alt="" />
                </div>
               )
                   return ( 
                   <div className="item product-slide-img">
                <img src={backendUrl+value.image_path} alt="" />
              </div>
               )
               })} */}
               
             
            {/* <div className="product-slide-content">
              <a href="javascript:void(0);" className="brdr-btn">
                Add To Cart
              </a>
            </div>
            <p>Ladies Cotton Suit With Kota Doriya.. </p>
            <h5>Rs. 1,549.00</h5>
          </div>
        </div> */}
        {/* <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="trending-innr">
            <div className="owl-carousel owl-theme trending-product-slide">
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-02.png" alt="" />
              </div>
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-01.png" alt="" />
              </div>
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-03.png" alt="" />
              </div>
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-02.png" alt="" />
              </div>
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-02.png" alt="" />
              </div>
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-02.png" alt="" />
              </div>
              <div className="item product-slide-img">
                <img src="assets/images/trending-product-02.png" alt="" />
              </div>
            </div> */}
            {/* <div className="product-slide-content">
              <a href="javascript:void(0);" className="brdr-btn">
                Add To Cart
              </a> */}
            {/* </div>
            <p>Ladies Cotton Suit With Kota Doriya.. </p>
            <h5>Rs. 1,549.00</h5>
          </div> */}
        {/* </div> */}
              </div>
    </div>
  </section>
</>
    
   
  )
}

export default ProductDetails
